<template>
  <a-card :bordered="true">
    <div class="txt-20 txt-bold txt-black txt-font-din-medium mb-10">
      {{ $t('components.titles.dataQualitySnapshot') }}
      <a-tooltip
        :title="$t('components.toolTips.sectionProvidesASnapshotOfDataQuality')"
        class="ml-8">
        <a-icon type="info-circle" style="color: #c6c7c6; font-size: 20px"/>
      </a-tooltip>
    </div>
    <div v-if="isLoading" class="w100 loading-container">
      <a-spin/>
    </div>
    <table v-else class="w100 data-quality-table">
      <thead class="txt-uppercase txt-grey txt-14">
        <th>{{ $t('components.labels.type') }}</th>
        <th>{{ $t('components.labels.score') }}</th>
        <th>{{ $t('components.labels.classification') }}</th>
      </thead>

      <tr v-for="(property, key) in entries" :key="key" class="txt-16">
        <td>{{ translateTitle[key] }}</td>
        <td>{{ property.score }} {{ property.value != null && property.value != '' ? `(${property.value})` : `${property.value}` }}</td>
        <td>{{ translateOutcome[property.outcome] }}</td>
      </tr>
    </table>
  </a-card>
</template>

<script>
import { getRandomColor } from '../../../util/util';

export default {
  name: 'DataQualityAnalysis',
  props: {
    entries: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    translateOutcome() {
      const outcomes = {
        Excellent: this.$t('components.labels.excellent'),
        Good: this.$t('components.labels.good'),
        Acceptable: this.$t('components.labels.acceptable'),
        Problematic: this.$t('components.labels.problematic')
      };
      return outcomes;
    },
    translateTitle() {
      const titles = {
        ageratio: this.$t('components.titles.ageRatioSixToTwentyNineVsThirtyToFiftyNine'),
        dpsheight: this.$t('components.titles.digPrefScoreHeight'),
        dpsmuac: this.$t('components.titles.digPrefScoreMUAC'),
        dpsweight: this.$t('components.titles.digPrefScoreWeight'),
        flagged: this.$t('components.titles.flaggedData'),
        overall: this.$t('components.titles.overallScoreWhz').toUpperCase(),
        sd: this.$t('components.titles.standardDevWhz'),
        sexratio: this.$t('components.titles.overallSexRatio')
      };
      return titles;
    }
  },
  methods: {
    getColor(teamIds) {
      if (teamIds.length === 1) {
        return {
          opacity: getRandomColor(teamIds[0], 0.1),
          full: getRandomColor(teamIds[0], 1)
        };
      }

      return {
        opacity: '#fff',
        full: '#fff'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-container {
  text-align: center;
  padding-top: 30px;
  min-height: 300px;
}
.household-analysis-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 60px 1fr 50px;
  gap: 0px 0px;
  grid-template-areas:
    "."
    "."
    "."
    ".";
  min-width: 100%;
  overflow-x: scroll;
}

.household-analysis-header,
.household-analysis-row,
.household-analysis-footer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto;
  align-items: center;
  text-align: center;
  justify-items: center;
}

.household-analysis-rows {
  max-height: 275px;
  overflow-x: scroll
}

.household-analysis-row {
  min-height: 30px;
  border-bottom: solid 1px #97979757;
  &:last-child {
    border-bottom: none;
  }
}

.household-analysis-header {
  background: #f6f6f6;
  border-top: solid 1px #97979757;
  border-bottom: solid 1px #97979757;

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
  }
}

.household-analysis-footer {
  border-top: solid 1px #97979757;
}

.household-analysis-row-team-column {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 3px;
    background-color: var(--teamColor);
  }
}

.not-accessible {
  background: #ffe4e4;
  color: #be0600;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
